// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_EVENT = '/posts';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  // loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  // register: path(ROOTS_AUTH, '/register'),
  // registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  // resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
  dashboardPosts: '/post',
  dashboardPost: '/post/:title',
  aboutUs: {
    coreConvictions: '/core-convictions',
    whatWeBelieve: '/what-we-believe',
    briefHistory: '/brief-history',
    whosWho: '/whos-who',
    howToGive: '/how-to-give',
    location: '/location',
    contactUs: '/contact-us',
    whatWeSing: '/what-we-sing',
    tadika: '/tadika'
  },
  imNew: {
    toJesus: '/new-to-jesus',
    toChurch: '/new-to-church',
  },
  connect: {
    christianityExplored: '/christianity-explored',
    homeFellowshipGroups: '/home-fellowship-groups',
    sundaySchool: '/sunday-school',
    englishYouthMinistry: '/english-youth-ministry',
    focus: '/focus',
    ladiesFellowship: '/ladies-fellowship',
    prayer: '/prayer',
    serving: '/serving',
  },
  resources: {
    usefulLinks: '/useful-links',
    recommendedReading: '/recommended-reading',
    weddingPolicyGuidelines: '/wedding-policy-guidelines',
    privacyPolicy: '/privacy-policy'
  }
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey'),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    // account: path(ROOTS_DASHBOARD, '/user/account'),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),
    editPost: path(ROOTS_DASHBOARD, '/blog/edit-post/:title'),
  },
};

export const PATH_EVENTS = {
  root: ROOTS_EVENT,
  post: path(ROOTS_EVENT, '/post/:title'),
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
export const PATH_SERMONS = 'https://sermons.bemkec.my';
