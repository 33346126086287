// @mui
import { styled } from '@mui/material/styles';
import { Grid, Container, Typography, Box } from '@mui/material';
// components
import Page from '../../components/Page';
import Image from 'src/components/Image';
import { ProductDetailsCarousel } from 'src/sections/@dashboard/e-commerce/product-details';
import { CategoryCard } from '../connect/HomeFellowshipGroups';
// sections

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(5),
  },
}));

export default function Tadika() {
  return (
    <Page title="Tadika Rajawali">
      <RootStyle>
        <Container sx={{ mt: 15, mb: 10, px: 3 }}>
          <Grid container justifyContent="center" sx={{ mt: 5 }}>
            <Grid item xs={12} md={6} lg={3}>
              <Image
                src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1741530308/bemkec/tadika/TR_Website_xz5l2c.webp"
                sx={{
                  borderRadius: 2,
                  width: '100%',
                }}
              />
            </Grid>
          </Grid>

          <Typography variant="h2" sx={{ mb: 5, textAlign: 'center' }}>
            Tadika Rajawali
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="body1">
                Tadika Rajawali is a church kindergarten founded by Madam Lim Siok Hong in 1997 with the aim of providing children with a Christian environment where they
                can enjoy learning and develop holistically. We offer a three-year curriculum for children aged four to six, based on the National Preschool
                Standard-Based Curriculum (KSPK). We also build children's character by instilling Christian moral values in them.
                <br /> <br />
              </Typography>
            </Grid>{' '}
          </Grid>
          <Grid>
            <Grid container spacing={8}>
              <Grid item xs={12} md={12}>
                <Typography variant="h3" sx={{ mb: 2, pt: 5, textAlign: 'left' }}>
                  MOTTO
                </Typography>

                <Typography variant="body1" sx={{ textAlign: 'left', fontSize: '2.75rem' }}>
                  Strive to excel!
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="h3" sx={{ mb: 3, textAlign: 'left' }}>
                  PHILOSOPHY
                </Typography>
                <Typography variant="body1" sx={{ mb: 3, textAlign: 'left' }}>
                  Our philosophy concerning children is based on the teaching of the Bible. As such, we believe that every child
                </Typography>
                {/* <ul style={{ marginLeft: 20 }}>
                    <li> is precious in God's sight,</li>
                    <li> has certain talents which can be developed,</li>
                    <li> is unique and significant, and </li>
                    <li> can become the person he/she is intended to be. </li>
                  </ul> */}
                <Box
                  sx={{
                    mt: 2,
                    mb: 2,
                    display: 'grid',
                    gap: 2,
                    gridTemplateColumns: {
                      xs: 'repeat(1, 1fr)',
                      sm: 'repeat(2, 1fr)',
                      md: 'repeat(2, 1fr)',
                      lg: 'repeat(2, 1fr)',
                    },
                  }}
                >
                  {Philosophy.map((category) => (
                    <CategoryCard key={category.label} category={category} />
                  ))}
                </Box>
                At our kindergarten, every child will be regarded as worthy of love and care because of the infinite value God has placed upon human life.
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h3" sx={{ mb: 3, textAlign: 'left' }}>
                  OBJECTIVES
                </Typography>

                <Typography variant="body1" sx={{ textAlign: 'left', mr: { lg: 10 } }}>
                  Our objectives are:
                </Typography>
                <ol style={{ marginLeft: 20 }}>
                  <li>To provide a caring Christian environment in which children will enjoy learning.</li>
                  <li>
                    To assist in the overall development of children
                    <Box
                      sx={{
                        mt: 1,
                        mb: 2,
                        display: 'grid',
                        gap: 1,
                        gridTemplateColumns: {
                          xs: 'repeat(1, 1fr)',
                          sm: 'repeat(2, 1fr)',
                          md: 'repeat(2, 1fr)',
                          lg: 'repeat(3, 1fr)',
                        },
                      }}
                    >
                      {Objectives.map((category) => (
                        <CategoryCard key={category.label} category={category} />
                      ))}
                    </Box>
                  </li>
                  <li>To build children's character by providing good role models and instilling Christian moral values in them.</li>
                  <li>To lead children to know God personally.</li>
                  <li>To prepare children for Primary One.</li>
                </ol>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography variant="h3" sx={{ pt: 5, mb: 3, textAlign: 'left' }}>
                WHAT KIDS LEARN
              </Typography>
              <Box
                sx={{
                  mb: 2,
                  display: 'grid',
                  gap: 2,
                  gridTemplateColumns: {
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                    md: 'repeat(4, 1fr)',
                    lg: 'repeat(5, 1fr)',
                  },
                }}
              >
                {KidsLearn.map((category) => (
                  <CategoryCard key={category.label} category={category} />
                ))}
              </Box>
              <Typography variant="body1" sx={{ mb: 3, textAlign: 'left' }}>
                All subjects will be taught in English except Bahasa Malaysia and Chinese.
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="h3" sx={{ pt: 5, mb: 3, textAlign: 'left' }}>
            ENRICHMENT PROGRAMME
          </Typography>
          <Typography variant="h4" sx={{ mb: 3, textAlign: 'center' }}>
            STRIVE <br />
            (Soaring To Reach Inspiring Values and Excellence)
          </Typography>
          <Typography variant="body1" sx={{ mb: 3, textAlign: 'left' }}>
            STRIVE is an additional course conducted in the afternoon that aligns with the Standard National Preschool Curriculum. It is designed to enrich children's
            education and to foster their holistic development through exposure to diverse, fun, and engaging activities.
          </Typography>

          <Grid container spacing={10}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" sx={{ pt: 5, mb: 3, textAlign: 'left' }}>
                Objectives
              </Typography>

              <Typography variant="body1" sx={{ mb: 2, textAlign: 'left' }}>
                Children will be able to:
              </Typography>
              <Box
                sx={{
                  mb: 2,
                  display: 'grid',
                  gap: 2,
                  gridTemplateColumns: {
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                    lg: 'repeat(2, 1fr)',
                  },
                }}
              >
                {StriveObjectives.map((category) => (
                  <CategoryCard key={category.label} category={category} />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" sx={{ pt: 5, mb: 3, textAlign: 'left' }}>
                STRIVE OFFERS
              </Typography>

              <Box
                sx={{
                  mb: 2,
                  display: 'grid',
                  gap: 2,
                  gridTemplateColumns: {
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                    lg: 'repeat(2, 1fr)',
                  },
                }}
              >
                {StriveOffers.map((category) => (
                  <CategoryCard key={category.label} category={category} />
                ))}
              </Box>
            </Grid>
          </Grid>

          <Grid container justifyContent="center" sx={{ mt: 5 }}>
            <Grid item xs={12} md={6} lg={8}>
              <Image
                src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1740496892/bemkec/misc/TadikaLogo_taw6kg.webp"
                sx={{
                  borderRadius: 2,
                  width: '100%',
                }}
              />
            </Grid>
          </Grid>

          <Box
            sx={{
              my: 5,
              display: 'grid',
              gap: 3,
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(2, 1fr)',
                lg: 'repeat(2, 1fr)',
              },
            }}
          >
            {TadikaRajawali.map((category) => (
              <CategoryCard key={category.label} category={category} />
            ))}
          </Box>

          <ProductDetailsCarousel
            product={{
              id: 'tadika-carousel',
              cover: '',
              name: 'Tadika Rajawali',
              price: 0,
              images: TRimages,
              code: '',
              sku: '',
              inventoryType: 'in_stock',
              createdAt: new Date().toISOString(),
              priceSale: 0,
              colors: [],
              status: '',
              available: 0,
              sizes: [],
              gender: '',
              category: '',
              description: '',
              sold: 0,
              totalRating: 0,
              totalReview: 0,
              ratings: [],
              reviews: [],
              tags: [],
            }}
          />

          <Typography variant="h3" sx={{ pt: 5, mb: 3, textAlign: 'left' }}>
            SCHOOL HOURS
          </Typography>
          <Typography variant="body1" sx={{ mb: 3, textAlign: 'left' }}>
            Monday to Friday
            <br />
            <br />
            Morning: 8:00 a.m. – 12:00 noon <br />
            Additional Course (Enrichment programme): 12:15 p.m. - 5:00 p.m.
          </Typography>

          <Typography variant="h3" sx={{ pt: 5, mb: 3, textAlign: 'left' }}>
            CONTACT INFORMATION
          </Typography>

          <Typography variant="h5" sx={{ pb: 2, textAlign: 'left' }}>
            Location
          </Typography>

          <Typography variant="body1" sx={{ textAlign: 'left' }}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.407792716841!2d110.34104537529953!3d1.5235778984621606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31fba76f70b201bb%3A0xb0cff3f493eb07fe!2sBEM%20Kuching%20Evangelical%20Church%20(previously%20SIB)!5e0!3m2!1sen!2smy!4v1699345487049!5m2!1sen!2smy"
              width="100%"
              height="550"
              loading="lazy"
              style={{ border: 0 }}
              allowFullScreen={true}
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            1863, Lorong Laksamana Cheng Ho 8, Iris Garden, 93200 Kuching, Sarawak
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Typography variant="h5" sx={{ pt: 5, pb: 2, textAlign: 'left' }}>
                Contact Number
              </Typography>
              <Typography variant="body1" sx={{ textAlign: 'left' }}>
                082-550251
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant="h5" sx={{ pt: 5, pb: 2, textAlign: 'left' }}>
                Email
              </Typography>
              <Typography variant="body1" sx={{ textAlign: 'left' }}>
                <a href="mailto:tadikarajawali@yahoo.co.uk">tadikarajawali@yahoo.co.uk</a>
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant="h5" sx={{ pt: 5, pb: 2, textAlign: 'left' }}>
                Facebook
              </Typography>
              <Typography variant="body1" sx={{ textAlign: 'left' }}>
                <a href="https://www.facebook.com/TadikaRajawali/">https://www.facebook.com/TadikaRajawali/</a>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </RootStyle>
    </Page>
  );
}

const TRimages = [
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1741530159/bemkec/tadika/8_simfh4.webp',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1741530159/bemkec/tadika/9_hniqzc.webp',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1741530160/bemkec/tadika/10_iulwfr.webp',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1741530160/bemkec/tadika/11_jadjqf.webp',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1741530161/bemkec/tadika/12_sdebop.webp',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1741530172/bemkec/tadika/21_t6i7r4.webp',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1741530162/bemkec/tadika/13_wbibjd.webp',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1741530163/bemkec/tadika/14_ujkmrv.webp',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1741530164/bemkec/tadika/15_aj5t21.webp',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1741530165/bemkec/tadika/16_sastax.webp',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1741530167/bemkec/tadika/17_ebd2tf.webp',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1741530168/bemkec/tadika/18_awlpqh.webp',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1741530169/bemkec/tadika/19_o5jvcs.webp',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1741530170/bemkec/tadika/20_uqoedl.webp',
];

const KidsLearn = [
  { label: 'English', description: '', extra: '' },
  { label: 'Bahasa Malaysia', description: '', extra: '' },
  { label: 'Chinese', description: '', extra: '' },
  { label: 'New Mathematics', description: '', extra: '' },
  { label: 'STEAM', description: '', extra: '' },
  { label: 'Arts & Crafts', description: '', extra: '' },
  { label: 'Music & Movement', description: '', extra: '' },
  { label: 'Physical Education', description: '', extra: '' },
  { label: 'Outdoor Play', description: '', extra: '' },
  { label: 'Biblical Moral Lesson', description: '', extra: '' },
];

const Objectives = [
  { label: 'Spiritual', description: '', extra: '' },
  { label: 'Emotional', description: '', extra: '' },
  { label: 'Intellectual', description: '', extra: '' },
  { label: 'Physical', description: '', extra: '' },
  { label: 'Social', description: '', extra: '' },
];

const Philosophy = [
  { label: "is precious in God's sight", description: '', extra: '' },
  { label: 'has certain talents which can be developed,', description: '', extra: '' },
  { label: 'is unique and significant, and ', description: '', extra: '' },
  { label: 'can become the person he/she is intended to be. ', description: '', extra: '' },
];

const StriveObjectives = [
  { label: 'Improve their communication and social skills.', description: '', extra: '' },
  { label: 'Practise moral values and standards in their daily lives.', description: '', extra: '' },
  { label: 'Develop various physical skills such as fine motor skills, gross motor skills, manipulative skills and rhythmic movement.', description: '', extra: '' },
  { label: 'Unleash creativity and imagination through art and music.', description: '', extra: '' },
  { label: 'Enhance their critical thinking and problem-solving skills', description: '', extra: '' },
];

const StriveOffers = [
  { label: 'Fun Activities', description: '', extra: '' },
  { label: 'Fun with Maths', description: '', extra: '' },
  { label: 'Practical Life Skills', description: '', extra: '' },
  { label: 'Moral and Character Education', description: '', extra: '' },
  { label: 'Revision and Guidance', description: '', extra: '' },
];

const TadikaRajawali = [
  {
    label: 'The Cross',
    description:
      'The cross in our logo represents our commitment to providing a Christian environment rooted in biblical teachings, nurturing each child’s holistic growth and supporting their overall development and well-being.',
    extra: '',
  },
  {
    label: 'The Soaring Eagle',
    description:
      'The soaring eagle represents our school community’s aspiration to reach new heights, embracing hope and resilience as we strive for excellence in all aspects.',
    extra: '',
  },
  {
    label: 'The Vine',
    description:
      "The vine serves as a reminder of Jesus' teaching that He is the true vine and we are the branches, symbolising our interconnectedness and encouraging us to live like Jesus, bringing a positive influence to those around us.",
    extra: '',
  },
  {
    label: 'The Colourful Wording',
    description:
      'The choice of colourful wording reflects the diversity within our school community, reminding us that every individual is wonderfully different and unique. These differences foster a dynamic and harmonious environment where creativity, growth and unity can flourish.',
    extra: '',
  },
];
