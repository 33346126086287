import { useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Container, Typography, Box, Paper } from '@mui/material';
// components
import Page from '../../components/Page';
import Image from 'src/components/Image';
// sections
const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(5),
  },
}));

const CATEGORIES = [
  {
    label: 'Agape Riveria HFG Leader:',
    description: 'Desmond Theng',
    href: '#',
  },
  {
    label: 'Arang Road HFG Leader:',
    description: 'David Bong',
    href: '#',
  },
  {
    label: 'Batu Kawa HFG Leader:',
    description: 'Steven Kueh',
    href: '#',
  },
  {
    label: 'Cornerstone HFG Leader:',
    description: 'Chung Zheng How',
    href: '#',
  },

  {
    label: 'Green Road HFG Leader:',
    description: 'Alan Bong',
    href: '#',
  },
  {
    label: 'Iris Garden HFG Leader:',
    description: 'Law Yew Kiung',
    href: '#',
  },
  {
    label: 'Livingstones HFG Leader:',
    description: 'Benjamin Baru',
    href: '#',
  },
  {
    label: 'Rockview HFG Leader:',
    description: 'Ferguson Tan',
    href: '#',
  },
  {
    label: 'Satria Jaya HFG Leader:',
    description: 'Lau Cheng Yuon',
    href: '#',
  },
  {
    label: 'Seng Goon Garden HFG Leader:',
    description: 'Paulus Amin',
    href: '#',
  },
  {
    label: 'Sunny 13 HFG Leader:',
    description: 'Francis King',
    href: '#',
  },
  {
    label: 'Tabuan Laru HFG Leader:',
    description: 'Patrick Teo',
    href: '#',
  },
];
export default function HomeFellowshipGroups() {

  return (
    <Page title="Home Fellowship Groups">
      <RootStyle>
        <Container sx={{ mt: 15, mb: 10 }}>
          <Typography variant="h2" sx={{ mb: 5, textAlign: 'center' }}>
            Home Fellowship Groups
          </Typography>
          <Typography variant="h4" sx={{ mb: 2 }}>
            What is Home Fellowship Group (HFG)?
          </Typography>
          HFG is a small group of our church regulars who meet once a week to connect, support and
          spend some quality time with each other. Each week, our HFGs will study God’s Word through
          some helpful material provided by the church. We also share our needs and keep one another
          in prayer.
          <Typography variant="h4" sx={{ mb: 2, mt: 3 }}>
            Who can join a HFG?
          </Typography>
          If you’re a regular in our church who hasn’t joined one yet, or a newcomer who plans to
          stay with us, then you’re highly encouraged to join one of our HFGs!
          <Typography variant="h4" sx={{ mb: 2, mt: 3 }}>
            Why should I join a HFG?
          </Typography>
          Our church’s core convictions are to be a Gospel-centred, Word-driven, disciple growing,
          truth-in-love community of believers. HFGs are an excellent place to grow and practice
          these beliefs as a church; we open God’s Word, teach, love and care for each other as
          believers.
          <Typography variant="h4" sx={{ mb: 2, mt: 3 }}>
            How do I join a HFG?
          </Typography>
          You may contact the pastor, the elders or any of the HFG leaders to ask for advice which
          HFG might be most suitable. Their contact details will be in the church bulletin when you
          come visit. The HFGs will gladly invite you into their groups the next time they meet! See
          you there!


          <br />
          <br />
          Alternatively, you may express your interest to join a HFG by filling a form here:
          
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfLGzygw8aR9kPqsrQqctdLMOSqBmCQF3fZ-eSc6gJuAykwTA/viewform?embedded=true" width="640" height="1308" frameBorder="0">Loading…</iframe>
          
          <Typography variant="h4" sx={{ mb: 2, mt: 3 }}>
            List of Home Fellowship Groups and respective group leaders as of 2023:
          </Typography>
          <Box
            sx={{
              mb: 2,
              display: 'grid',
              gap: 3,
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(4, 1fr)',
                lg: 'repeat(4, 1fr)',
              },
            }}
          >
            {CATEGORIES.map((category) => (
              <CategoryCard key={category.label} category={category} />
            ))}
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}

type CategoryCardProps = {
  category: {
    label: string;
    description: string;
    extra?: string;
  };
};

export function CategoryCard({ category }: CategoryCardProps) {
  const { label, description, extra } = category;

  return (
    <Paper
      variant="outlined"
      sx={{
        p: 1,
        height: '110%',
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        boxShadow: (theme) => theme.customShadows.z24,
        '&:hover': {
          boxShadow: (theme) => theme.customShadows.z1,
        },
      }}
    >
      <Typography variant="subtitle2">{label}</Typography>
      <Typography variant="body1">{description}</Typography>
      <Typography variant="caption">{extra}</Typography>
    </Paper>
  );
}